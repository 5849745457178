<template>
	<header class="navbar navbar-expand-lg mb-3">
		<div class="container">
			<div class="navbar-brand">
				Jessie Patterson
				<strong>{{$t('acupuncture')}}</strong>
			</div>
			<div class="navbar-collapse">
				<ul class="navbar-nav">
					<li
						class="nav-item"
						:class="{ active: route.name === $route.name }"
						v-for="(route, index) in routes"
						v-bind:key="index">
						<router-link
							class="nav-link"
							href="#"
							:to="route.path"
							v-html="$t(route.display)"></router-link>
					</li>
				</ul>
			</div>
			<ul class="navbar-nav mr-1">
				<li
					class="nav-item"
				>
					<BookNowButton />
				</li>
			</ul>
			<div class="navbar-text" v-if="translationsAreDone">
				<LocalizationToggle />
			</div>
		</div>
	</header>
</template>

<script>
import LocalizationToggle from '@/components/LocalizationToggle'
import BookNowButton from './BookNowButton'

export default {
	components: {
		BookNowButton,
		LocalizationToggle
	},
	computed: {
		routes () {
			return this.$router.options.routes
		}
	},
	data () {
		return {
			translationsAreDone: false
		}
	}
}
</script>
